<script setup lang="ts">
import { useDisplay } from "vuetify";
import PrimaryColors from "@/components/PrimaryColors.vue";
// import ToolbarNotifications from "./ToolbarNotifications.vue";
import ToolbarUser from "./ToolbarUser.vue";
// import { useTodoStore } from "@/stores/todoStore";
import ThemeToggle from "./ThemeToggle.vue";
const { mdAndUp } = useDisplay();
// const todoStore = useTodoStore();

import { useCustomizeThemeStore } from "@/stores/customizeThemeStore";
const customizeTheme = useCustomizeThemeStore();
</script>

<template>
  <v-app-bar :density="mdAndUp ? 'default' : 'compact'">
    <v-app-bar-nav-icon
      @click="customizeTheme.mainSidebar = !customizeTheme.mainSidebar"
    ></v-app-bar-nav-icon>
    <div class="px-2 d-flex align-center justify-space-between w-100">
      <v-btn class="text-none" to="/home" stacked> AIGC导航 </v-btn>
      <v-btn class="text-none position-relative" to="/code-magic" stacked>
        <span class="pa-2">
          代码魔法
        </span>
        <v-icon
          size="18"
          color="green"
          class="position-absolute"
          style="top: 12px; right: 2px;"
        >
          mdi-leaf
        </v-icon>
      </v-btn>
      <v-btn class="text-none" to="/guide/quick-start" stacked> 快速上手 </v-btn>
      <v-btn class="text-none" to="/guide/docs" stacked> 进阶技巧 </v-btn>
      <v-btn class="text-none position-relative" to="/aui" stacked>
        <span class="pa-2">
          纯血鸿蒙组件库
        </span>
        <v-icon
          size="18"
          color="red"
          class="position-absolute"
          style="top: 12px; right: 2px;"
        >
          mdi-fire
        </v-icon>
      </v-btn>
      <v-btn class="text-none" to="/update-logs" stacked> 升级日志 </v-btn>
      <v-btn class="text-none" to="/help" stacked> 帮助与反馈 </v-btn>

      <v-spacer></v-spacer>

      <div class="d-flex">
<!--        <ToolbarNotifications />-->
<!--        <v-btn v-if="mdAndUp" icon to="/apps/todo">-->
<!--          <v-badge :content="`${todoStore.getTodoList.length} +`" color="error">-->
<!--            <v-icon>mdi-calendar-check</v-icon>-->
<!--          </v-badge>-->
<!--        </v-btn>-->
<!--        <v-divider vertical thickness="2" inset class="ml-5 mr-1"></v-divider>-->
        <!--切换深色模式/浅色模式-->
        <ThemeToggle />
        <!--选择主题色-->
        <PrimaryColors />
        <ToolbarUser />
      </div>
    </div>
  </v-app-bar>
</template>

<style scoped lang="scss"></style>
