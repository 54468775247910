<script setup lang="ts">
import { SnackbarStore } from "@/stores/SnackbarStore";
const snackbarStore = SnackbarStore();

const getIcon = (type) => {
  const icon = {
    info: "mdi-information",
    success: "mdi-check-circle",
    error: "mdi-alert-circle",
    warning: "mdi-alert",
  };

  return icon[type];
};
</script>
<template>
  <div>
    <v-snackbar
      v-model="snackbarStore.isShow"
      :timeout="snackbarStore.type==='success' ? 1000 : 2000"
      :color="snackbarStore.type"
      class="elevation-10"
      location="top"
    >
      <div class="d-flex align-center">
        <v-icon class="mr-2">{{ getIcon(snackbarStore.type) }}</v-icon>
        <span> {{ snackbarStore.message }}</span>
      </div>

      <template v-slot:actions>
        <v-btn icon variant="text" @click="snackbarStore.isShow = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
