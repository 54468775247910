import menuApp from "./menus/app.menu"
import pageApp from "./menus/page.menu"
import menuCoder from "./menus/coder.menu"
import guideCoder from "./menus/guide.menu"

export default {
  menu: [
    {
      text: "应用设计",
      items: menuApp
    },
    {
      text: "页面设计",
      items: pageApp
    },
    {
      text: "代码生成",
      items: menuCoder
    },
    {
      text: "开发指南",
      items: guideCoder
    }
  ]
}
