import { createRouter, createWebHistory } from "vue-router"
import PromptsRoutes from "./prompts.routes"
import ModelsRoutes from "./models.routes"
import AuthRoutes from "./auth.routes"
import AppRoutes from "./app.routes"
import DomainRoutes from "./domain.routes"
import DtoRoutes from "./dto.routes"
import VoRoutes from "./vo.routes"
import ResourceRoutes from "./resource.routes"
import GrpcRoutes from "./grpc.routes"
import NavigationRoutes from "./navigation.routes"
import PageRoutes from "./page.routes"
import CoderRoutes from "./coder.routes"
import OrderRoutes from "./order.routes"


export const routes = [
  {
    path: "/",
    redirect: "/home"
  } as any,
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home/Home.vue"),
    meta: {
      requiresAuth: true,
      layout: "empty",
    }
  },
  {
    path: "/code-magic",
    name: "code-magic",
    component: () => import("@/views/code_magic/Home.vue"),
    meta: {
      requiresAuth: true,
      layout: "empty",
    }
  },
  {
    path: "/guide/quick-start",
    name: "guide-quick-start",
    component: () => import("@/views/guide/QuickStart.vue"),
    meta: {
      requiresAuth: true,
      layout: "empty",
    }
  },
  {
    path: "/guide/docs",
    name: "guide-docs",
    component: () => import("@/views/guide/Docs.vue"),
    meta: {
      requiresAuth: true,
      layout: "empty",
    }
  },
  {
    path: "/aui",
    name: "aui",
    component: () => import("@/views/guide/Aui.vue"),
    meta: {
      requiresAuth: true,
      layout: "empty",
    }
  },
  {
    path: "/article/:id",
    name: "guide-detail",
    component: () => import("@/views/guide/Detail.vue"),
    meta: {
      requiresAuth: true,
      layout: "empty",
    }
  },
  {
    path: "/update-logs",
    name: "update-logs",
    component: () => import("@/views/update_logs/Index.vue"),
    meta: {
      requiresAuth: true,
      layout: "empty",
    }
  },
  {
    path: "/help",
    name: "help",
    component: () => import("@/views/help/Index.vue"),
    meta: {
      requiresAuth: true,
      layout: "empty",
    }
  },




// TODO 代码元宇宙
  // {
  //   path: "/test",
  //   name: "test",
  //   component: () => import("@/views/test/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },
  // {
  //   path: "/task",
  //   name: "task",
  //   component: () => import("@/views/task/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },
  // {
  //   path: "/qinglong",
  //   name: "qinglong",
  //   component: () => import("@/views/qinglong/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },
  // {
  //   path: "/baihu",
  //   name: "baihu",
  //   component: () => import("@/views/baihu/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },
  // {
  //   path: "/zhuque",
  //   name: "zhuque",
  //   component: () => import("@/views/zhuque/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },
  // {
  //   path: "/xuanwu",
  //   name: "xuanwu",
  //   component: () => import("@/views/xuanwu/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },
  // {
  //   path: "/secret",
  //   name: "secret",
  //   component: () => import("@/views/secret/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },
  // {
  //   path: "/top",
  //   name: "top",
  //   component: () => import("@/views/top/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },
  // {
  //   path: "/study",
  //   name: "study",
  //   component: () => import("@/views/study/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },
  // {
  //   path: "/story",
  //   name: "story",
  //   component: () => import("@/views/story/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },



  // TODO 以下内容归入“AI秘境”板块
  // {
  //   path: "/chatbot",
  //   name: "chatbot",
  //   component: () => import("@/views/chatbot/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },
  // {
  //   path: "/imagebot",
  //   name: "imagebot",
  //   component: () => import("@/views/imagebot/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },
  // {
  //   path: "/news",
  //   name: "news",
  //   component: () => import("@/views/news/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },
  // {
  //   path: "/compare",
  //   name: "compare",
  //   component: () => import("@/views/compare/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },
  // {
  //   path: "/comfyui",
  //   name: "comfyui",
  //   component: () => import("@/views/comfyui/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },
  // {
  //   path: "/market",
  //   name: "market",
  //   component: () => import("@/views/market/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     layout: "empty",
  //   }
  // },




  {
    path: "/:pathMatch(.*)*",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/errors/NotFoundPage.vue"),
  },
  ...PromptsRoutes,
  ...ModelsRoutes,
  ...AuthRoutes,
  ...AppRoutes,
  ...DomainRoutes,
  ...DtoRoutes,
  ...VoRoutes,
  ...ResourceRoutes,
  ...GrpcRoutes,
  ...NavigationRoutes,
  ...PageRoutes,
  ...CoderRoutes,
  ...OrderRoutes
]

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = []

const router = createRouter({
  history: createWebHistory(),
  // hash模式：createWebHashHistory，history模式：createWebHistory
  // process.env.NODE_ENV === "production"

  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
