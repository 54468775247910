export default [
  {
    path: "/code-magic/quarkus",
    component: () => import("@/views/code_magic/Quarkus.vue"),
    meta: {
      layout: "coder",
      category: "代码生成",
      title: "Quarkus / SpringBoot",
    },
  },
  {
    path: "/code-magic/fastapi",
    component: () => import("@/views/code_magic/FastApi.vue"),
    meta: {
      layout: "coder",
      category: "代码生成",
      title: "FastApi",
    },
  },
  {
    path: "/code-magic/vuetify",
    component: () => import("@/views/code_magic/Vuetify.vue"),
    meta: {
      layout: "coder",
      category: "代码生成",
      title: "Vuetify",
    },
  },
  {
    path: "/code-magic/harmony",
    component: () => import("@/views/code_magic/Harmony.vue"),
    meta: {
      layout: "coder",
      category: "代码生成",
      title: "Harmony",
    },
  },
];
