<script setup lang="ts">
import navigation from "@/configs/coder_navigation";
import MainMenu from "@/components/MainMenu.vue";
import { useCustomizeThemeStore } from "@/stores/customizeThemeStore";
const customizeTheme = useCustomizeThemeStore();

const openWebSite = () => {
  window.open("/home", "_self");
};

onMounted(() => {
  scrollToBottom();
});

const scrollToBottom = () => {
  const contentArea = document.querySelector(".v-navigation-drawer__content");
  const activeItem = document.querySelector(
    ".v-list-item--active"
  ) as HTMLElement;

  setTimeout(() => {
    contentArea?.scrollTo({
      top: activeItem?.offsetTop,
    });
  }, 100);
};
</script>

<template>
  <v-navigation-drawer
    elevation="1"
    v-model="customizeTheme.mainSidebar"
    id="mainMenu"
  >
    <!---品牌 -->
    <template v-slot:prepend>
      <v-card height="100" class="logo-card" @click="openWebSite">
        <h1 class="logo-text h-full">代码魔法</h1>
      </v-card>
    </template>

    <!---左侧栏菜单 -->
    <div class="main-menu">
      <main-menu :menu="navigation.menu"></main-menu>
    </div>
    <!---来源 -->
    <template v-slot:append>
      <v-card height="225" class="pa-3" variant="text">
        <v-card class="d-flex flex-column pa-2 gradient-card text-white" height="200 ">
          <v-card-title>
            <v-btn class="mr-2" size="40" color="white" icon>
              <img src="@/assets/logo.png" :width="30" alt="AIGCoder"/>
            </v-btn>
            AIGCoder
          </v-card-title>
          <v-card-text>
            <div><b>AIGC低代码平台</b></div>
            <div>AIGCoder.com</div>
          </v-card-text>
          <v-card-actions>
            <v-btn block prepend-icon="mdi-web" variant="outlined" @click="openWebSite" color="white">
              AI极客官网
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </template>
  </v-navigation-drawer>
</template>

<style scoped lang="scss">
.gradient-card {
  // background: linear-gradient(
  //   to bottom,
  //   rgba(var(--v-theme-primary), 1),
  //   rgba(var(--v-theme-primary), 0.9)
  // );
  background: linear-gradient(
    270deg,
    rgba(var(--v-theme-primary), 0.7) 0,
    rgb(var(--v-theme-primary)) 100%
  );
  box-shadow: 0 2px 6px rgba(var(--v-theme-primary), 0.3);
}

.logo-card {
  .logo-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 600;
    color: rgba(var(--v-theme-primary));
  }
}
</style>
