export default [
  {
    icon: "mdi-language-java",
    key: "menu.quarkus",
    text: "Java",
    link: "/code-magic/quarkus",
  },
  {
    icon: "mdi-language-python",
    key: "menu.fastapi",
    text: "Python",
    link: "/code-magic/fastapi",
  },
  {
    icon: "mdi-cast-variant",
    key: "menu.vuetify",
    text: "Vuetify",
    link: "/code-magic/vuetify",
  },
  {
    icon: "mdi-tablet-cellphone",
    key: "menu.harmony",
    text: "纯血鸿蒙",
    link: "/code-magic/harmony",
  }
]
